.gameCard{
  margin: auto;
}

.textStyle,.descriptionStyle{
  margin: auto;
  color:white;
  font-size: 2.5rem;
  width: 150vh;
  font-family: 'Press Start 2P';
  margin-top: 5vh;
  text-align: center;
}

.descriptionStyle{
  font-size: 2rem;
  margin-top: 5vh;
}

.gifGroup{
  display: flex;
  margin: auto;
  margin-top: 10vh;
  justify-content:center;
}

.gifGroup img{
  width: 90vh;
}
